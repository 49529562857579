@media print, screen and (min-width: 500px) {
  .label-kiri-pertama{
    margin-top: 1.7cm;
    width: 340.158px !important;
  }
  .label-kiri-pertama .label-kiri{
    width: 2cm !Important;
    font-size: 14px !Important;
  }
  .label-kiri-pertama tr td{
    /*margin-top: 1.7cm !Important;*/
    /*border-spacing: 0cm !Important;*/
    /*width: 9cm !Important;*/
    padding: 0px !Important;
    font-size: 14px !Important;
  }
  .label-kiri-pertama .label-kanan{
    width: 7cm !important;
  }
  .label-kanan-pertama{
    position: absolute;
    left: 9.7cm;
    top:  0.93cm;
    font-size: 14px !Important;
    width: 4.5cm;
  }
  .label-kanan-pertama .label-kiri{
    width: 1.45cm !Important;
    font-size: 14px !Important;
  }
  .jam{
    position: absolute;
    left: 18.4cm;
    top:  1.3cm;
    font-size: 14px !Important;
  }
  .jenis-layanan{
    position: absolute;
    left: 17cm;
    top:  1.8cm;
    font-size: 14px !Important;
  }
  .label-kiri-kedua{
    position: absolute;
    top: 3.2cm;
    font-size: 14px !Important;
  }
  .label-kiri-kedua .label-kiri{
    width: 1.95cm !Important;
    font-size: 14px !Important;
    font-size: 14px !Important;
  }
  .label-kiri-kedua .label-kanan{
    width: 1.6cm !important;
    font-size: 14px !Important;
  }
  .label-kiri-ketiga{
    position: absolute;
    top: 7cm;
    font-size: 14px !Important;
  }
  .label-kiri-ketiga .label-kiri{
    width: 2.85cm !Important;
    font-size: 14px !Important;
  }

  .label-kanan-kedua{
    position: absolute;
    left: 9.15cm;
    top:  2.5cm;
    font-size: 14px !Important;
    width: 9.05cm;
  }

  .pokok{
    text-align: right;
    width: 3.15cm;
    font-size: 14px !Important;
    padding-right: 0.6cm;
  }

  .sanksi{
    text-align: right;
    width: 2.85cm;
    font-size: 14px !Important;
    padding-right: 0.6cm;
  }
  .sanksi{
    text-align: right;
    width: 3.15cm;
    font-size: 14px !Important;
    padding-right: 0.6cm;
  }
  .tgl-penetapan{
    position: absolute;
    left: 9.65cm;
    top:  5.5cm;
    width: 2.9cm;
    font-size: 14px !Important;
  }
  .petugas-penetapan{
    position: absolute;
    left: 12.155cm;
    top:  5.5cm;
    width: 4.45cm;
    font-size: 14px !Important;
  }
  .korektor{
    position: absolute;
    left: 17.1cm;
    top:  5.5cm;
    width: 3.35cm;
    font-size: 14px !Important;
  }
  .ttd1{
    position: absolute;
    top: 6.1cm;
    left: 9.0cm;
    text-align: center;
    font-size: 6px;
  }
  .footer-ttd{
    margin-top: 0.2cm;
  }
  .ttd2{
    position: absolute;
    top: 6.1cm;
    left: 13.1cm;
    text-align: center;
    font-size: 6px;
  }
  .ttd3{
    position: absolute;
    top: 5.9cm;
    left: 17.2cm;
    text-align: center;
    font-size: 6px;
  }

}

@media print {
* {
    -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
    color-adjust: exact !important;  /*Firefox*/
  }
  
}