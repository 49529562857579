.laporan-table {
    -fs-table-paginate: paginate;
    font-size: 12px;
}

.kertas-laporan {
    width: 210mm;
    height: 330mm;
    padding: 10px;
}

.kertas-laporan-a4 {
    width: 210mm;
    height: 297mm;
    padding: 10px;
}

.kertas-laporan-besar {
    width: 14.88in;
    height: 11.00in;
}

.teks-kanan {
    text-align: right;
}

.teks-tengah {
    text-align: center;
}

.judul-laporan {
    font-size: 14px;
    margin-bottom: 15px;
}

.titik-dua:after {
    content: ":";
}

.laporan-table thead {
    border-top: double 5px #000000;
    border-bottom: solid 3px #000000;
}

.laporan-table tbody {
    border-bottom: double 3px #000000;
}

.tebal {
    font-weight: bold;
}

@media print {
    .pagebreak {
        page-break-after: always;

    }
}

.judul-laporan-sts {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    position: relative;
}

.setoran-ke {
    font-size: 10px;
}

.judul-laporan-sts img {
    position: absolute;
    left: 0;
    height: 60px;
}

.kepada .samsat {
    font-weight: bold;
}

.bold {
    font-weight: bold;
}

.deskripsi {
    margin-top: 30px;
}

.total-sts {
    margin-top: 30px;
}

.text-center {
    text-align: center;
}