@media print, screen and (min-width: 801px) {
  #invoice-POS{
    /*box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5);*/
    /*border: 1px solid #cccccc !important;*/
    padding:2mm;
    /*margin: 0 auto;*/
    width: 75mm;
    background: #FFF;
    color:  #000000;
  }  
  #invoice-POS-a4{
    /*box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5);*/
    /*border: 1px solid #cccccc;*/
    padding:2mm;
    /*margin: 0 auto;*/
    width: 29.7cm;
    background: #FFF;
    color:  #000000;
  }  
  .resep{
    /*box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5);*/
    /*border: 1px solid #cccccc !important;*/
    color:  #000000;
    border-radius: 10px;
    padding:2mm;
    /*margin: 0 auto;*/
    width: 75mm;
    background: #FFF;
    margin-bottom: 0.3cm;
  }  
  .resep-nama{
    font-size: 16px;
    font-weight: bold;
  }
  .resep-obat{
    font-size: 14px;
    font-weight: bold;
  }
  .resep-dosis{
    font-size: 12px;
  }
  .bg-jenis{
    background: #BEC1BD !important;
    color: #FFFFFF !important;
    float: left;
    padding: 10px;
    font-weight: bold;
  }
  .paraf-panel{
    clear: both;
    margin-top: 20px;
  }
  .paraf-kiri{
    float: left;
    text-align: center;
    margin-left: 2cm;
  }
  .paraf-kanan{
    float:right;
    text-align: center;
    margin-right: 2cm;
  }
  .paraf{
    margin-top: 2cm;
    font-weight: bold;
  }
  .no-meja{
    border: 1px solid #000000;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    font-weight: bold;
  }
  .registrasi-no-urut{
    font-size: 32px;
    font-weight: bold;
    transform: scale(2.5);
    margin-bottom: 10px;
    color: #000000;
  }
  .registrasi-nama{
    font-size: 24px;
  }
  .registrasi-footer{
    font-size: 12px;
  }
  /*  
  ::selection {background: #f31544; color: #FFF;}
  ::moz-selection {background: #f31544; color: #FFF;}
  h1{
    font-size: 1.5em;
    color: #222;
  }
  h2{font-size: .9em;}
  h3{
    font-size: 1.2em;
    font-weight: 300;
    line-height: 2em;
  }
  p{
    font-size: .7em;
    color: #666;
    line-height: 1.2em;
  }
  */
  #top, #mid{ /* Targets all id with 'col-' */
    border-bottom: 1px dashed #000000 !important;
  }

 /*

  #top .logo{
  	height: 60px;
  	width: 60px;
  	background-size: 60px 60px;
  }
  .clientlogo{
    float: left;
  	height: 60px;
  	width: 60px;
  	background-size: 60px 60px;
    border-radius: 50px;
  }
  */
  .info{
    display: block;
    /*/float:left;*/
    margin-left: 0;
  }
  .nama-outlet{
    font-size: 12px;
    font-weight: bold;
  }
  .alamat{
    font-size: 12px;
  }
  .panel-info{
    font-size: 10px;
    margin-bottom: 0.3cm;
  }
  .info p{
    font-size: 10px;
  }
  .title{
    float: right;
  }
  .title p{text-align: right;} 
  table{
    width: 100%;
    
  }
  td{
    /*//padding: 5px 0 5px 15px;
    //border: 1px solid #EEE*/
  }
  .tabletitle td{
    /*//padding: 5px;*/
    font-size: 12px;
    background: #EEE;
    font-weight: bold;
  }
  
  .item{width: 24mm;}
  .itemtext{font-size: 10px;}
  .itemtextright{text-align: right;font-size: 10px;}

  .invoice-column-name{
    font-size: 10px;
    border-top: 1px dashed #000000 !important;
    border-bottom: 1px dashed #000000 !important;
    text-align: center;
  }
  .invoice-column-name-pembelian{
    font-size: 10px;
    /*
    border-top: 1px dashed #000000 !important;
    border-bottom: 1px dashed #000000 !important;
    */
    background: #C4C4C4 !important;
    font-weight: bold;
    text-align: center;
  }
  .label-item{
    width: 40%;
  }
  .label-qty{
    width: 10%;
  }
  .label-harga{
    width: 20%;
  }
  .label-subtotal{
    width: 30%;
    text-align: right;
  }

  .invoice-summary-panel-up{
    border-top: 1px dashed #000000 !important;
    padding-top: 20px;
  }

  .right-bold{
    text-align: right;
    font-weight: bold;
    font-size: 10px;
  }

  .right-thin{
    text-align: right;
    font-size: 10px;
  }

  #legalcopy{
    margin-top: 5mm;
    font-size: 10px;
  }
}

@media print {
* {
    -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
    color-adjust: exact !important;  /*Firefox*/
  }
}