@media print, screen and (min-width: 500px) {
  .header-surat{
    border-bottom: double 4px #000000 !important;
    text-align: center;
  }
  .header-surat .kop{
    font-size: 14px;
    font-weight: bold;
  }
  .header-surat .sub-kop{
    font-size: 12px;
  }
  .header-surat .sub-kop2{
    font-size: 11px;
  }
  .header-surat .kop-logo{
    position: absolute;
    left: 20px;
    top:  0px;
  }
  .judul-surat{
    font-weight: bold;
    text-decoration-line: underline;
    font-size: 14px;
    text-align: center;
  }
  .nomor-surat{
    font-size: 10px;
    text-align: center;
  }
  .berdasarkan{
    font-weight: bold;
  }
  .biodata-fiskal{
    margin-top: 20px;
  }
  .titik-dua::after{
    content: ":";
    text-align: right;
    float:right;
    margin-right: 10px;
  }
  .ttd-fiskal{
    text-align: center;
  }
  .nama-ttd-fiskal{
    margin-top: 50px;
    font-weight: bold;
    text-decoration-line: underline;
  }
  .jabatan-ttd-fiskal{
    font-weight: bold;
  }

}

@media print {
* {
    -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
    color-adjust: exact !important;  /*Firefox*/
  }
  
}