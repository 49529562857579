.ant-steps-item-icon{
  background: #0A5C24 !important;
}
.login-daftar-link{
  color: #0A5C24 !important;
}
.ant-steps-icon{
  color: #ffffff !important;
}
.ant-steps-finish-icon{
  background: #0A5C24;
  color: #DEA806;
}
.btn-primary{
  background: #0A5C24 !important;
  color: #FFFFFF !important;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active{
  background: #0A5C24;
  color: #FFFFFF;
}
.splash {
  background: #fff;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.title-splash{
  font-size: 24px;
  font-weight: 700;
  color: #1897FF;
  margin-top: 50px;
}
.eclipse{
  position: absolute;
  width: 147px;
  height: 147px;
  left: calc(100% - 50% - (147px / 2));
  top: -114px;
  background: #DA251D;
  border-radius: 50%;
}
.eclipse-bawah{
  position: absolute;
  width: 147px;
  height: 147px;
  left: -50px;
  bottom: -50px;
  background: #DA251D;
  border-radius: 50%;
}
.form {
  position: absolute;
  top: 45%;
  left: 50%;
  margin: -160px 0 0 -160px;
  width: 320px;
  /*height: 320px;*/
  padding: 36px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);
}

.form-pendaftaran {
  width: 80%;
  /*height: 320px;*/
  padding: 36px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);
  margin: 0px auto;
}

/*
p {
    color: rgb(204, 204, 204);
    text-align: center;
    margin-top: 16px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
}
*/
.logo-login {
  text-align: center;
  cursor: pointer;
  
}

.logo-login img {
  /*width: 40px;*/
  width: 100%;
}

.logo-login span {
  vertical-align: text-bottom;
  font-size: 16px;
  text-transform: uppercase;
  
  font-weight: 700;
  color: #138AFF;
}

.ant-spin-container,
.ant-spin-nested-loading {
  height: 100%;
}

.footer {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.ant-modal-title{
	text-align: center;
	font-weight: bold;
	color: #000;
}
.blur-qr {
	filter: blur(10px);
	-webkit-filter: blur(10px);
	-moz-filter: blur(10px);
	-o-filter: blur(10px);
	-ms-filter: blur(10px);
}

